import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './UserTree.css'; // Import the CSS file
import Loader from "../../components/Loader/Loader";

// Function to transform the hierarchical data into levels
const transformDataToLevels = (members, maxLevels = 8) => {
  const levels = [];
  const map = new Map();
  
  members.forEach(member => {
    if (!map.has(member.parentId)) {
      map.set(member.parentId, []);
    }
    map.get(member.parentId).push(member);
  });

  const buildLevels = (parentId, level = 0) => {
    if (level >= maxLevels) return; // Stop building levels after reaching maxLevels
    if (map.has(parentId)) {
      const currentLevel = map.get(parentId);
      levels[level] = currentLevel;
      currentLevel.forEach(member => buildLevels(member.memberId, level + 1));
    }
  };

  buildLevels(null);
  return levels;
};

const UserTree = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approvedStatuses, setApprovedStatuses] = useState({});
  const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchUserTree = async () => {
    try {
      const response = await fetch(`${APP_BASE_URL}/user-tree/${localStorage.getItem('userId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });
      const data = await response.json();

      if (data.success) {
        setMembers(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Error: ' + error.message);
    }
  };

  const handleClaim = async (commissionId) => {
    setLoading(true);
    try {
      const response = await fetch(APP_BASE_URL + "/updateReqPaymentStatus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ id: commissionId }),
      });
      const data = await response.json();

      if (data.success) {
        toast.success(data.message);
        setApprovedStatuses((prevStatuses) => ({
          ...prevStatuses,
          [commissionId]: true,
        }));
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserTree();
  }, []);

  const levels = transformDataToLevels(members, 8);

  // Determine the main user (level 1) and users starting from level 2
  const mainUser = levels[0] ? levels[0][0] : null;
  const subLevels = levels.slice(1); // Exclude level 1

  return (
    <div id="mainContainer">
      {loading && <Loader />}
      {mainUser && (
        <div className="mainUserHeading">
          <h2>User ( {mainUser.name} )</h2>
        </div>
      )}
      {subLevels.map((level, index) => (
        <div key={index} className="levelTable">
          <h3>Level {index +1} Referral Users </h3>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Commission</th>
                <th>Claim</th>
              </tr>
            </thead>
            <tbody>
              {level && level.map((member) => (
                <tr key={member.memberId}>
                  <td>{member.name}</td>
                  <td>$ {member.commission || 'N/A'}</td>
                  <td>
                    {member.commission && member.paymentStatus == null && !approvedStatuses[member.commission_id] ? (
                      <button onClick={() => handleClaim(member.commission_id)}>Claim</button>
                    ) : (
                      <span>{member.paymentStatus === 2 ? 'Approved' : 'Pending'}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default UserTree;
